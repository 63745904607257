import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import { sectionListDocs } from 'utils/sidebar/section-list'

const DocsTemplate = ({ data, location }) => {
  const page = data.markdownRemark
  const { fields, frontmatter, html } = page

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <Layout
        location={location}
        showSidebar={true}
        sectionList={sectionListDocs}
      >
        <h1 id={fields.anchor} css={{ marginTop: 0 }}>
          {frontmatter.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Layout>
    </>
  )
}

export default DocsTemplate

export const pageQuery = graphql`
  query TemplateDocsMarkdown($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      excerpt
      timeToRead
      fields {
        slug
        anchor
      }
      frontmatter {
        title
      }
    }
  }
`
